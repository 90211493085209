import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { ThemeProvider as TaniumThemeProvider } from '@tanium/react-theme-context';
import { Box } from '@tanium/react-box';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import { bluestar } from '@tanium/theme-data';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignedOutPage from './pages/SignedOutPage';
import { configureApp } from './utils/config';

const App: React.VFC = () => {
  const [configSucceded, setConfigSucceeded] = useState(false);

  useEffect(() => {
    configureApp()
      .then(() => {
        setConfigSucceeded(true);
      })
      .catch((error) => {
        // TODO - display failure notification
        // eslint-disable-next-line no-console
        console.log(error);
        setConfigSucceeded(false);
      });
  }, []);

  return (
    <EmotionThemeProvider theme={bluestar}>
      <TaniumThemeProvider theme={bluestar}>
        {!configSucceded && (
          <Box height="100vh">
            <TaniumSpinner />
          </Box>
        )}
        {configSucceded && (
          <BrowserRouter>
            <Switch>
              <Route path="/home">
                <HomePage />
              </Route>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/">
                <SignedOutPage />
              </Route>
            </Switch>
          </BrowserRouter>
        )}
      </TaniumThemeProvider>
    </EmotionThemeProvider>
  );
};

export default App;
