"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppleProfileStateEntity = exports.ProfileState = void 0;
const datamgmt_lib_1 = require("@tanium-cloud/datamgmt-lib");
var ProfileState;
(function (ProfileState) {
    ProfileState["Requested"] = "Requested";
    ProfileState["Applied"] = "Applied";
    ProfileState["Error"] = "Error";
    ProfileState["FormatError"] = "FormatError";
    ProfileState["RemovalRequested"] = "RemovalRequested";
    ProfileState["Removed"] = "Removed";
    ProfileState["RescindedBeforeApplied"] = "RescindedBeforeApplied";
})(ProfileState = exports.ProfileState || (exports.ProfileState = {}));
let AppleProfileStateEntity = class AppleProfileStateEntity {
    constructor(accountId, deviceId, commandInstanceId) {
        this.payloadUUID = undefined;
        this.payloadIdentifier = undefined;
        this.createdOn = undefined;
        this.appliedOn = undefined;
        this.removedOn = undefined;
        this.errorOn = undefined;
        this.state = undefined;
        this.accountId = accountId;
        this.deviceId = deviceId;
        this.commandInstanceId = commandInstanceId;
    }
};
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    Reflect.metadata(datamgmt_lib_1.Entity.Key, true),
    __metadata("design:type", String)
], AppleProfileStateEntity.prototype, "accountId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    Reflect.metadata(datamgmt_lib_1.Entity.Key, true),
    __metadata("design:type", String)
], AppleProfileStateEntity.prototype, "deviceId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    Reflect.metadata(datamgmt_lib_1.Entity.Key, true),
    __metadata("design:type", String)
], AppleProfileStateEntity.prototype, "commandInstanceId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleProfileStateEntity.prototype, "payloadUUID", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleProfileStateEntity.prototype, "payloadIdentifier", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleProfileStateEntity.prototype, "createdOn", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleProfileStateEntity.prototype, "appliedOn", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleProfileStateEntity.prototype, "removedOn", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleProfileStateEntity.prototype, "errorOn", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleProfileStateEntity.prototype, "state", void 0);
AppleProfileStateEntity = __decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.EntityVersion, '1.0'),
    __metadata("design:paramtypes", [String, String, String])
], AppleProfileStateEntity);
exports.AppleProfileStateEntity = AppleProfileStateEntity;
exports.default = AppleProfileStateEntity;
