/* eslint-disable no-console */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel } from '@tanium/react-panel';
import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import { toDateString } from '../UIHelpers';
import TenantContext from './TenantContext';

const Device: React.VFC = () => {
  const history = useHistory();
  const { currentDevice } = useContext(TenantContext);

  const showDeviceList = () => {
    history.push('devices');
  };

  return (
    <>
      <Button onClick={showDeviceList}>Back to Device List</Button>
      <Box mt={2}>
        <Panel title="Device Info">
          <Box m={2}>
            <DefinitionList>
              <DefinitionListItem term="Device Id" description={currentDevice?.deviceId}></DefinitionListItem>
              <DefinitionListItem term="Platform" description={currentDevice?.platform}></DefinitionListItem>
              <DefinitionListItem term="User Id" description={currentDevice?.userId}></DefinitionListItem>
              <DefinitionListItem
                term="Enrollment Status"
                description={currentDevice?.enrollmentStatus}
              ></DefinitionListItem>
              <DefinitionListItem
                term="Enrollment Started"
                description={toDateString(currentDevice?.enrollmentStartedTime)}
              ></DefinitionListItem>
              <DefinitionListItem
                term="Enrollment Completed"
                description={toDateString(currentDevice?.enrollmentCompletedTime)}
              ></DefinitionListItem>
              <DefinitionListItem
                term="Last checked in"
                description={toDateString(currentDevice?.lastCheckInTime)}
              ></DefinitionListItem>
              <DefinitionListItem
                term="Client Cert ARN"
                description={currentDevice?.clientCertArn}
              ></DefinitionListItem>
              <DefinitionListItem
                term="Client Cert Expiration"
                description={toDateString(currentDevice?.clientCertExpirationTime)}
              ></DefinitionListItem>
              <DefinitionListItem
                term="Apple DeviceInfo First Retrieved"
                description={toDateString(currentDevice?.appleFirstDeviceInfoTime)}
              ></DefinitionListItem>
              <DefinitionListItem
                term="Apple Applications First Installed"
                description={toDateString(currentDevice?.appleFirstInstalledApplicationsTime)}
              ></DefinitionListItem>
            </DefinitionList>
          </Box>
        </Panel>
      </Box>
    </>
  );
};

export default Device;
