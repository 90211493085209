"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommandResultStatus = void 0;
var CommandResultStatus;
(function (CommandResultStatus) {
    CommandResultStatus["Idle"] = "Idle";
    CommandResultStatus["Acknowledged"] = "Acknowledged";
    CommandResultStatus["Error"] = "Error";
    CommandResultStatus["CommandFormatError"] = "CommandFormatError";
    CommandResultStatus["NotNow"] = "NotNow";
})(CommandResultStatus = exports.CommandResultStatus || (exports.CommandResultStatus = {}));
exports.default = CommandResultStatus;
