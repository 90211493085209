/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

export const getUsername = async (): Promise<string> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const userSession = user.getSignInUserSession();
    if (!userSession?.isValid()) {
      return '';
    }
    return user.getUsername();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return '';
  }
};

/**
 * Gets an access token for the currently authenticated user, or undefined if there is not a valid user session.
 * Refreshes the access token if the current one is expired.
 */
export const getAccessToken = async (): Promise<string | undefined> => {
  try {
    // This will automatically refresh the accessToken and idToken if they are expired
    // and the refreshToken is a valid. Otherwise it will return the cached tokens.
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    return undefined;
  }
};
