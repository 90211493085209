"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Classification {
}
exports.Classification = Classification;
Classification.Type = Symbol.for('Type_440E79C4-8C60-4803-9BF3-08ED5FF0B82F');
Classification.PII = Symbol.for('PII_847FC05A-8F9F-4595-B759-88246C28CA87');
Classification.OII = Symbol.for('OII_3BF9C520-1834-47D3-A5B6-177DB0CAC7AD');
Classification.PSI = Symbol.for('PSI_8853F97A-C1CF-4DEF-B6D0-04B050620C9D');
Classification.CPI = Symbol.for('CPI_476F2957-8264-4ED9-A9F0-065EBB0D7D82');
Classification.SYS = Symbol.for('System_FE9ECFAB-22D9-40E4-8CA9-2794A32AB99C');
exports.default = Classification;
