"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceEntity = exports.AppleDeviceConfigurationState = exports.EnrollmentStatus = exports.Platform = void 0;
require("reflect-metadata");
const datamgmt_lib_1 = require("@tanium-cloud/datamgmt-lib");
var Platform;
(function (Platform) {
    Platform["Windows"] = "Windows";
    Platform["Apple"] = "Apple";
    Platform["Android"] = "Android";
})(Platform = exports.Platform || (exports.Platform = {}));
var EnrollmentStatus;
(function (EnrollmentStatus) {
    EnrollmentStatus["Unenrolled"] = "Unenrolled";
    EnrollmentStatus["PendingCert"] = "PendingCert";
    EnrollmentStatus["CertIssued"] = "CertIssued";
    EnrollmentStatus["AppleAuthenticated"] = "AppleAuthenticated";
    EnrollmentStatus["EnrollmentComplete"] = "EnrollmentComplete";
    EnrollmentStatus["EnrollmentFailed"] = "EnrollmentFailed";
})(EnrollmentStatus = exports.EnrollmentStatus || (exports.EnrollmentStatus = {}));
var AppleDeviceConfigurationState;
(function (AppleDeviceConfigurationState) {
    AppleDeviceConfigurationState["NoneApplied"] = "NoneApplied";
    AppleDeviceConfigurationState["AppleProductDefaultApplied"] = "AppleProductDefaultApplied";
    AppleDeviceConfigurationState["DeviceSpecificProfileApplied"] = "DeviceSpecificProfileApplied";
})(AppleDeviceConfigurationState = exports.AppleDeviceConfigurationState || (exports.AppleDeviceConfigurationState = {}));
let DeviceEntity = class DeviceEntity {
    constructor(accountId, deviceId, platform, enrollmentStatus, userId) {
        this.userId = undefined;
        this.appleProductName = undefined;
        this.applePushToken = undefined;
        this.applePushMagic = undefined;
        this.appleUnlockToken = undefined;
        this.serialNumber = undefined;
        this.imei = undefined;
        this.meid = undefined;
        this.enrollmentStartedTime = undefined;
        this.enrollmentCompletedTime = undefined;
        this.lastCheckInTime = undefined;
        this.clientCertArn = undefined;
        this.clientCertExpirationTime = undefined;
        this.deletedAt = undefined;
        this.appleFirstDeviceInfoTime = undefined;
        this.appleFirstInstalledApplicationsTime = undefined;
        this.appleDeviceConfigurationState = AppleDeviceConfigurationState.NoneApplied;
        this.appleConfigPayloadHash = undefined;
        this.abmTokenId = undefined;
        this.accountId = accountId;
        this.deviceId = deviceId;
        this.platform = platform;
        this.enrollmentStatus = enrollmentStatus;
        this.userId = userId;
    }
};
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    Reflect.metadata(datamgmt_lib_1.Entity.Key, true),
    __metadata("design:type", String)
], DeviceEntity.prototype, "accountId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    Reflect.metadata(datamgmt_lib_1.Entity.Key, true),
    __metadata("design:type", String)
], DeviceEntity.prototype, "deviceId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "userId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PSI),
    __metadata("design:type", String)
], DeviceEntity.prototype, "platform", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PSI),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "appleProductName", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PSI),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "applePushToken", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PSI),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "applePushMagic", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PSI),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "appleUnlockToken", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PII),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "serialNumber", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PII),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "imei", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PII),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "meid", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", String)
], DeviceEntity.prototype, "enrollmentStatus", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "enrollmentStartedTime", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "enrollmentCompletedTime", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "lastCheckInTime", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.PSI),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "clientCertArn", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "clientCertExpirationTime", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "deletedAt", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "appleFirstDeviceInfoTime", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "appleFirstInstalledApplicationsTime", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", String)
], DeviceEntity.prototype, "appleDeviceConfigurationState", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "appleConfigPayloadHash", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], DeviceEntity.prototype, "abmTokenId", void 0);
DeviceEntity = __decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.EntityVersion, '1.0'),
    __metadata("design:paramtypes", [String, String, String, String, Object])
], DeviceEntity);
exports.DeviceEntity = DeviceEntity;
exports.default = DeviceEntity;
