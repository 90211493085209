import { EnrollmentStatus, Platform } from '@tanium-cloud/mdm-device-entities';

export type ConfigResponse = {
  mdmDomainName: string;
  instanceList: string[];
  internalCognitoAppClient: string;
  internalCognitoDomain: string;
  internalCognitoAdditionalCallbackUrls: string[];
  internalCognitoUserPoolId: string;
  internalCognitoUserPoolRegion: string;
};

export type TenantInstanceInfo = {
  instance: string;
  deviceEnrollmentUrl: string;
  supportApiUrl: string;
};

export type TenantInfo = {
  accountId: string;
  accountName: string;
  domain: string;
  cognitoAppClientId: string;
  cognitoUserPoolId: string;
  cognitoUserPoolRegion: string;
};

export type ApnsConfigInfo = {
  accountId: string;
  deletedAt: string | undefined;
  pushCertArn: string;
  pushCertCsrPrivateKeyArn: string;
  pushCertExpiresOn: number;
  pushCertUploadedOn: number;
  pushCertPrivateKeyArn: string;
  pushTopic: string;
  pushCertSerialNumber: string;
  pushCertSubjectDN: string;
  pushCertAppleId: string;
};

export type DeviceInfo = {
  deviceId: string;
  accountId: string;
  platform: Platform;
  enrollmentStatus: EnrollmentStatus;
  userId: string;
  appleProductName: string;
  serialNumber: string;
  imei: string;
  meid: string;
  enrollmentStartedTime: number;
  enrollmentCompletedTime: number;
  lastCheckInTime: number;
  deletedAt: string;
  clientCertArn: string;
  clientCertExpirationTime: number;
  appleFirstDeviceInfoTime: number;
  appleFirstInstalledApplicationsTime: number;
};

export enum ResultStatus {
  APPLE_COMMAND_RESULT_STATUS_UNSPECIFIED = 'Unspecified',
  APPLE_COMMAND_RESULT_STATUS_IDLE = 'Idle',
  APPLE_COMMAND_RESULT_STATUS_ACKNOWLEDGED = 'Acknowledged',
  APPLE_COMMAND_RESULT_STATUS_ERROR = 'Error',
  APPLE_COMMAND_RESULT_STATUS_COMMAND_FORMAT_ERROR = 'Format error',
  APPLE_COMMAND_RESULT_STATUS_NOT_NOW = 'Not now',
}

export type AppleCommand = {
  deviceId: string;
  commandId: string;
  priority: number;
  body: string;
  resultBody: string;
  resultStatus: ResultStatus;
  createdOn: string;
  deliveredOn: string;
  resultOn: string;
};

export const DevTenantInstanceInfo: TenantInstanceInfo = {
  instance: 'localdev',
  deviceEnrollmentUrl: 'notvalid',
  supportApiUrl: 'http://localhost:8080/query',
};
