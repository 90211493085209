import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { getAccessToken } from './auth';
import {
  AppleCommand,
  ApnsConfigInfo,
  DeviceInfo,
  TenantInfo,
  TenantInstanceInfo,
  DevTenantInstanceInfo,
} from './types';

type GraphQLResponse = {
  data?: any;
  errors?: [{ message: string }];
};

// TODO Use real graphql library to query the aupport api service
async function fetchGraphQL(
  supportApiUrl: string,
  text: string,
  variables: object | undefined
): Promise<GraphQLResponse> {
  const accessToken = await getAccessToken();

  if (!accessToken) {
    throw new Error('Could not get access token');
  }

  const response = await fetch(supportApiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'activity-id': uuidv4(),
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });
  return response.json() as GraphQLResponse;
}

export const getTenantInstanceInfoByDomain = async (domain: string): Promise<TenantInstanceInfo | undefined> => {
  if (process.env.NODE_ENV === 'development') {
    return DevTenantInstanceInfo;
  }

  const response = await axios.get<TenantInstanceInfo>(`/discovery?domain=${domain}`);
  if (response.status === 403) {
    return undefined;
  }
  const instanceInfo = response.data as unknown as TenantInstanceInfo;
  instanceInfo.supportApiUrl = `${instanceInfo.deviceEnrollmentUrl.replace('deviceenrollment', 'support-api')}/query`;
  return instanceInfo;
};

export const getTenantByDomain = async (domain: string, supportApiUrl: string): Promise<TenantInfo | undefined> => {
  const response: GraphQLResponse = await fetchGraphQL(
    supportApiUrl,
    `
    query {
      accountByDomain(domain: "${domain}" ) {
        accountId
        accountName
        domain
        cognitoAppClientId
        cognitoUserPoolId
        cognitoUserPoolRegion
        deletedAt
        customerAdminUserName
      }
    }
  `,
    undefined
  );
  if (response.errors) {
    throw Error(response.errors[0].message);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data.accountByDomain as TenantInfo;
};

export const getApnsConfig = async (tenantId: string, supportApiUrl: string): Promise<ApnsConfigInfo | undefined> => {
  const response: GraphQLResponse = await fetchGraphQL(
    supportApiUrl,
    `
    query {
      apnsConfigById(accountId: "${tenantId}") {
        accountId
        deletedAt
        pushCertArn
        pushCertCsrPrivateKeyArn
        pushCertExpiresOn
        pushCertUploadedOn
        pushCertPrivateKeyArn
        pushTopic
        pushCertSerialNumber
        pushCertSubjectDN
        pushCertAppleId
      }
    }
  `,
    undefined
  );
  if (response.errors) {
    // TODO modify api service to return null data when not found instead of error
    return undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data.apnsConfigById as ApnsConfigInfo;
};

export const getDevicesByTenant = async (tenantId: string, supportApiUrl: string): Promise<DeviceInfo[]> => {
  const response: GraphQLResponse = await fetchGraphQL(
    supportApiUrl,
    `
    query($deviceInput: DevicesInput!) {
      devices(input: $deviceInput) {
        nodes {
          deviceId
          enrollmentStatus
          enrollmentStartedTime
          enrollmentCompletedTime
          lastCheckInTime
          platform
          userId
          clientCertArn
          clientCertExpirationTime
          appleFirstDeviceInfoTime
          appleFirstInstalledApplicationsTime
        }
      }
    }
  `,
    {
      deviceInput: {
        accountId: tenantId,
        filter: {},
      },
      first: 50,
    }
  );
  if (response.errors) {
    throw Error(response.errors[0].message);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data.devices.nodes as DeviceInfo[];
};

export const getAppleCommandsByDevice = async (
  deviceId: string,
  accountId: string,
  supportApiUrl: string
): Promise<AppleCommand[]> => {
  const response: GraphQLResponse = await fetchGraphQL(
    supportApiUrl,
    `
    query($input: GetAppleCommandsInput!) {
      getAppleCommands(input: $input) {
        nodes {
          deviceId
          commandId
          priority
          body
          resultBody
          resultStatus
          createdOn
          deliveredOn
          resultOn
        }
      }
    }
  `,
    {
      input: {
        accountId: `${accountId}`,
        deviceId: `${deviceId}`,
      },
    }
  );
  if (response.errors) {
    throw Error(response.errors[0].message);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data.getAppleCommands.nodes as AppleCommand[];
};

export const getAppleCommand = async (
  commandId: string,
  deviceId: string,
  accountId: string,
  supportApiUrl: string
): Promise<AppleCommand> => {
  const response: GraphQLResponse = await fetchGraphQL(
    supportApiUrl,
    `
    query($input: GetAppleCommandInput!) {
      getAppleCommand(input: $input) {
        deviceId
        body
        resultBody
      }
    }
  `,
    {
      input: {
        commandId: `${commandId}`,
        accountId: `${accountId}`,
        deviceId: `${deviceId}`,
      },
    }
  );
  if (response.errors) {
    throw Error(response.errors[0].message);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data.getAppleCommand as AppleCommand;
};

export default fetchGraphQL;
