/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Panel } from '@tanium/react-panel';
import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import TenantContext from './TenantContext';
import DevicesGrid from '../DevicesGrid';
import { getDevicesByTenant } from '../../utils/supportApi';
import { DeviceInfo } from '../../utils/types';

const Devices: React.VFC = () => {
  const history = useHistory();
  const [devices, setDevices] = useState<DeviceInfo[] | undefined>();
  const { tenant, instanceSupportApiUrl } = useContext(TenantContext);

  const showTenant = () => {
    history.push('/home');
  };

  useEffect(() => {
    getDevicesByTenant(tenant!.accountId, instanceSupportApiUrl!)
      .then((returnedDevices) => {
        setDevices(returnedDevices);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [instanceSupportApiUrl, tenant]);

  return (
    <>
      <Button onClick={showTenant}>Back to Tenant</Button>
      <Box mt={2}>
        <Panel title="Tenant Devices">
          {devices && (
            <DevicesGrid accountId={tenant!.accountId} devices={devices} instanceApiUrl={instanceSupportApiUrl!} />
          )}
        </Panel>
      </Box>
    </>
  );
};

export default Devices;
