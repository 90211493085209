/* eslint-disable no-console */
import React, { useCallback, useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ControlGroup, ControlGroupItem } from '@tanium/react-control-group';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import { GlobalIcon, SearchIcon, StateCriticalIcon } from '@tanium/react-graphics';
import { TextInput } from '@tanium/react-text-input';
import { TextWithIcon } from '@tanium/react-text-with-icon';
import { RED } from '@tanium/colors';
import { Button } from '@tanium/react-button';
import { Box } from '@tanium/react-box';
import { PageSection } from '@tanium/react-page-section';
import { Panel } from '@tanium/react-panel';
import { Ribbon } from '@tanium/react-ribbon';
import { Well } from '@tanium/react-well';
import { getApnsConfig, getTenantByDomain, getTenantInstanceInfoByDomain } from '../../utils/supportApi';
import { getCognitoMetadataUrl, toDateString } from '../UIHelpers';

import TenantContext from './TenantContext';

enum TenantRetrievalStatus {
  NOT_ATTEMPTED = 'NotAttempted',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
}

const FindTenant: React.VFC = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const [domain, setDomain] = useState('');
  const [isTenantRetrieved, setIsTenantRetrieved] = useState<TenantRetrievalStatus>(
    TenantRetrievalStatus.NOT_ATTEMPTED
  );
  const { tenant, setTenant, apnsConfig, setApnsConfig, setInstanceName, setInstanceInstanceSupportApiUrl } =
    useContext(TenantContext);

  const handleDomainChanged = useCallback(
    ({ currentTarget: { value } }) => {
      setDomain(value as string);
    },
    [setDomain]
  );

  const getTenant = async () => {
    try {
      setIsTenantRetrieved(TenantRetrievalStatus.NOT_ATTEMPTED);
      const retrievedInstanceInfo = await getTenantInstanceInfoByDomain(domain);
      if (retrievedInstanceInfo === undefined) {
        throw new Error('Could not find tenant in any instance');
      }
      setInstanceName(retrievedInstanceInfo.instance);
      setInstanceInstanceSupportApiUrl(retrievedInstanceInfo.supportApiUrl);
      const retrievedTenant = await getTenantByDomain(domain, retrievedInstanceInfo.supportApiUrl);
      setTenant(retrievedTenant);

      const retrievedApnsConfig = await getApnsConfig(retrievedTenant!.accountId, retrievedInstanceInfo.supportApiUrl);
      setApnsConfig(retrievedApnsConfig);
      setIsTenantRetrieved(TenantRetrievalStatus.SUCCEEDED);
    } catch (err) {
      console.warn('Error retrieving tenant');
      setTenant(undefined);
      setIsTenantRetrieved(TenantRetrievalStatus.FAILED);
    }
  };

  const viewDeviceList = () => {
    history.push(`${routeMatch.path}/devices`);
  };

  return (
    <>
      <Box width="100%" mt={1}>
        <Box>
          <Well>
            <PageSection title={<TextWithIcon leadingIcon={GlobalIcon}>Tenant Search</TextWithIcon>}>
              <ControlGroup>
                <ControlGroupItem grow={1}>
                  <TextInput
                    style={{ maxHeight: '1em' }}
                    type={'text'}
                    placeholder={'Enter tenant domain'}
                    value={domain}
                    onChange={handleDomainChanged}
                    data-testid="domain-input"
                  />
                </ControlGroupItem>
                <Button onClick={getTenant}>
                  <TextWithIcon leadingIcon={SearchIcon}>Search</TextWithIcon>
                </Button>
              </ControlGroup>
            </PageSection>
          </Well>
        </Box>
        {tenant && (
          <Box mt={2}>
            <Panel title="Tenant Info">
              <DefinitionList>
                <DefinitionListItem term="Tenant Name" description={tenant.accountName}></DefinitionListItem>
                <DefinitionListItem term="Account ID" description={tenant.accountId}></DefinitionListItem>
                <DefinitionListItem term="Domain" description={tenant.domain}></DefinitionListItem>
                <DefinitionListItem term="AppClient ID" description={tenant.cognitoAppClientId}></DefinitionListItem>
                <DefinitionListItem
                  term="Cognito Metadata URL"
                  description={
                    <a href={getCognitoMetadataUrl(tenant)} target="_blank" rel="noreferrer">
                      {getCognitoMetadataUrl(tenant)}
                    </a>
                  }
                ></DefinitionListItem>
                {apnsConfig?.pushCertUploadedOn ? (
                  <>
                    <DefinitionListItem term="Push Cert ARN" description={apnsConfig?.pushCertArn}></DefinitionListItem>
                    <DefinitionListItem
                      term="Push Cert Upload Date"
                      description={toDateString(apnsConfig?.pushCertUploadedOn)}
                    ></DefinitionListItem>
                    <DefinitionListItem
                      term="Push Cert Expiration Date"
                      description={toDateString(apnsConfig?.pushCertExpiresOn)}
                    ></DefinitionListItem>
                  </>
                ) : (
                  <DefinitionListItem term="Push Notification Status" description="Not configured"></DefinitionListItem>
                )}
              </DefinitionList>
              <Box mt={2}>
                <Button onClick={viewDeviceList}>View devices</Button>
              </Box>
            </Panel>
          </Box>
        )}
        {isTenantRetrieved === TenantRetrievalStatus.FAILED && (
          <Box mt={2}>
            <Ribbon
              borderColor={RED}
              sideBar={
                <StateCriticalIcon
                  height="16"
                  width="16"
                  style={{
                    color: RED,
                  }}
                />
              }
            >
              Tenant not found
            </Ribbon>
          </Box>
        )}
      </Box>
    </>
  );
};

export default FindTenant;
