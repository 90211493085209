"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppleInstalledApplicationsEntity = exports.AppleInstalledApplication = void 0;
require("reflect-metadata");
const datamgmt_lib_1 = require("@tanium-cloud/datamgmt-lib");
class AppleInstalledApplication {
}
exports.AppleInstalledApplication = AppleInstalledApplication;
let AppleInstalledApplicationsEntity = class AppleInstalledApplicationsEntity {
    constructor(accountId, deviceId) {
        this.installedApplications = undefined;
        this.accountId = accountId;
        this.deviceId = deviceId;
        this.receivedOn = Date.now();
    }
};
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    Reflect.metadata(datamgmt_lib_1.Entity.Key, true),
    __metadata("design:type", String)
], AppleInstalledApplicationsEntity.prototype, "accountId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    Reflect.metadata(datamgmt_lib_1.Entity.Key, true),
    __metadata("design:type", String)
], AppleInstalledApplicationsEntity.prototype, "deviceId", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Number)
], AppleInstalledApplicationsEntity.prototype, "receivedOn", void 0);
__decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.FirstSeenIn, '1.0'),
    Reflect.metadata(datamgmt_lib_1.Classification.Type, datamgmt_lib_1.Classification.SYS),
    __metadata("design:type", Object)
], AppleInstalledApplicationsEntity.prototype, "installedApplications", void 0);
AppleInstalledApplicationsEntity = __decorate([
    Reflect.metadata(datamgmt_lib_1.Versioning.EntityVersion, '1.0'),
    __metadata("design:paramtypes", [String, String])
], AppleInstalledApplicationsEntity);
exports.AppleInstalledApplicationsEntity = AppleInstalledApplicationsEntity;
exports.default = AppleInstalledApplicationsEntity;
