"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DynamoDb {
}
exports.DynamoDb = DynamoDb;
DynamoDb.PartitionKey = Symbol.for('PartitionKey_1BA95525-14E1-48AE-B8B1-1FDF88DA3686');
DynamoDb.SortKey1 = 'SortKey1';
DynamoDb.SortKey2 = 'SortKey2';
DynamoDb.SortKey3 = 'SortKey3';
DynamoDb.SortKey4 = 'SortKey4';
DynamoDb.UseSoftDelete = Symbol.for('UseSoftDelete_64630CD0-6615-4024-BCCF-9E87AB2C9FCA');
exports.default = DynamoDb;
