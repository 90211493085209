import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { Button } from '@tanium/react-button';
import { Stack } from '@tanium/react-stack';
import { css } from '@tanium/style-system';
import Header from '../components/Header/Header';
import TopContent from '../components/TopContent/TopContent';
import { PageContent } from './Styling';

const Container = styled(Box)(
  css({
    marginTop: '2',
    width: '100%',
  })
);

const SignedOutPage: React.VFC = () => {
  const history = useHistory();

  const handleSignInClick = () => {
    history.push('/login');
  };

  return (
    <Box>
      <Box>
        <Header username={undefined} />
        <PageContent>
          <TopContent />
          <Container>
            <Stack mt={5} justifyContent="center">
              <Box>You must sign in to use the service</Box>
            </Stack>
            <Stack mt={3} justifyContent="center">
              <Button onClick={handleSignInClick}>Sign in</Button>
            </Stack>
          </Container>
        </PageContent>
      </Box>
    </Box>
  );
};

export default SignedOutPage;
