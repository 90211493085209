/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { ApnsConfigInfo, DeviceInfo, TenantInfo } from '../../utils/types';

export interface TenantContextInterface {
  instanceName: string | undefined;
  setInstanceName: (name: string) => void;
  instanceSupportApiUrl: string | undefined;
  setInstanceInstanceSupportApiUrl: (url: string) => void;
  tenant: TenantInfo | undefined;
  setTenant: (tenant: TenantInfo | undefined) => void;
  apnsConfig: ApnsConfigInfo | undefined;
  setApnsConfig: (tenant: ApnsConfigInfo | undefined) => void;
  currentDevice: DeviceInfo | undefined;
  setCurrentDevice: (deviceId: DeviceInfo) => void;
}

const TenantContext = createContext({
  instanceName: undefined,
  setInstanceName: /* istanbul ignore next */ (name: string) => {},
  instanceSupportApiUrl: undefined,
  setInstanceInstanceSupportApiUrl: /* istanbul ignore next */ (url: string) => {},
  tenant: undefined,
  setTenant: /* istanbul ignore next */ (t: TenantInfo | undefined) => {},
  apnsConfig: undefined,
  setApnsConfig: /* istanbul ignore next */ (t: ApnsConfigInfo | undefined) => {},
  currentDevice: undefined,
  setCurrentDevice: /* istanbul ignore next */ (device: DeviceInfo) => {},
} as TenantContextInterface);

export default TenantContext;
