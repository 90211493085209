/* eslint-disable no-console */
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { ColumnsChangeEvent, DataGrid, DataColumn } from '@tanium/react-data-grid';
import {
  SlidingDetailsPanel,
  SlidingDetailsPanelContainer,
  useContainerPosition,
} from '@tanium/react-sliding-details-panel';
import { Well } from '@tanium/react-well';
import { getAppleCommand } from '../../utils/supportApi';
import { getWellKnownCommandName, prettyPrintXml } from '../UIHelpers';
import { AppleCommand } from '../../utils/types';
import TenantContext from './TenantContext';

interface Props {
  appleCommands: AppleCommand[];
}

const AppleComamndsGrid: React.VFC<Props> = ({ appleCommands }) => {
  const initialColumns: DataColumn[] = [
    {
      label: 'Command',
      dataKey: 'commandId',
      width: 280,
      cellRenderer: ({ rowData }: any): ReactElement => {
        const command = rowData as AppleCommand;
        return <span>{getWellKnownCommandName(command.commandId)}</span>;
      },
    },
    {
      label: 'Priority',
      dataKey: 'priority',
      width: 70,
      headerAlignment: 'center',
    },
    {
      label: 'Status',
      dataKey: 'resultStatus',
    },
    {
      label: 'Created',
      dataKey: 'createdOn',
    },
    {
      label: 'Delivered',
      dataKey: 'deliveredOn',
    },
    {
      label: 'Result',
      dataKey: 'resultOn',
    },
    {
      label: 'Command Body',
      dataKey: 'body',
      headerAlignment: 'center',
      cellRenderer: ({ rowData }: any): ReactElement => {
        const command = rowData as AppleCommand;
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button size="sm" onClick={() => viewCommandBody(command)}>
              View
            </Button>
          </Box>
        );
      },
    },
    {
      label: 'Result Body',
      dataKey: 'resultBody',
      headerAlignment: 'center',
      cellRenderer: ({ rowData }: any): ReactElement => {
        const command = rowData as AppleCommand;
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button size="sm" onClick={() => viewResultBody(command)}>
              View
            </Button>
          </Box>
        );
      },
    },
  ];

  const viewCommandBody = async (command: AppleCommand) => {
    await getCommandBodies(command);
    setIsCommandBodyPanelOpen(true);
  };

  const viewResultBody = async (command: AppleCommand) => {
    await getCommandBodies(command);
    setIsResultBodyPanelOpen(true);
  };

  const getCommandBodies = async (command: AppleCommand) => {
    const commandDetails = await getAppleCommand(
      command.commandId,
      command.deviceId,
      tenant!.accountId,
      instanceSupportApiUrl!
    );
    console.log(`commandDetails: ${JSON.stringify(commandDetails, null, 2)}`);
    setCommandBody(commandDetails.body);
    setResultBody(commandDetails.resultBody);
  };

  const { tenant, instanceSupportApiUrl } = useContext(TenantContext);
  const [columns, setColumns] = useState(initialColumns);
  const [data] = useState(appleCommands);
  const [isBodyPanelOpen, setIsCommandBodyPanelOpen] = useState<boolean>(false);
  const [isResultBodyPanelOpen, setIsResultBodyPanelOpen] = useState<boolean>(false);
  const [commandBody, setCommandBody] = useState<string | undefined>(undefined);
  const [resultBody, setResultBody] = useState<string | undefined>(undefined);

  const { containerRef, ...refScrollResizeValues } = useContainerPosition();

  /* istanbul ignore next */
  const onColumnsChange = React.useCallback(
    (e: ColumnsChangeEvent, applyChanges: (currentColumns: DataColumn[], newColumns: DataColumn[]) => DataColumn[]) => {
      const changedColumns = applyChanges(columns, e.columns);
      setColumns(changedColumns);
    },
    [columns]
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const dataGetter = useCallback(({ index: i }) => data[i], [data]);

  return (
    <Box as={SlidingDetailsPanelContainer} ref={containerRef}>
      <Well>
        <DataGrid
          height={400}
          columns={columns}
          onColumnsChange={onColumnsChange}
          isRowLoaded={() => true}
          rowCount={appleCommands.length}
          // @ts-expect-error dataGetter wants Device to have an `id` but we override that in rowIdGetter
          dataGetter={dataGetter}
          rowIdGetter={(i) => dataGetter(i).commandId}
        />
        <SlidingDetailsPanel
          title="Command Body"
          isOpen={isBodyPanelOpen}
          onCollapse={() => setIsCommandBodyPanelOpen(false)}
          panelToggleLabel="Close Panel"
          refScrollResizeValues={refScrollResizeValues}
          fullTopAdjust={25}
          fullBottomAdjust={26}
          topStopScroll={27}
          fullRightAdjust={10}
          widthPercentage={75}
        >
          <Box m={2} height="95%">
            <textarea
              value={commandBody}
              cols={80}
              style={{ border: 'none', overflowY: 'scroll', width: '100%', height: '100%' }}
            />
          </Box>
        </SlidingDetailsPanel>
        <SlidingDetailsPanel
          title="Result Body"
          isOpen={isResultBodyPanelOpen}
          onCollapse={() => setIsResultBodyPanelOpen(false)}
          panelToggleLabel="Close Panel"
          refScrollResizeValues={refScrollResizeValues}
          fullTopAdjust={25}
          fullBottomAdjust={26}
          topStopScroll={27}
          fullRightAdjust={10}
          widthPercentage={75}
        >
          <Box m={2} height="95%">
            <textarea
              value={prettyPrintXml(resultBody!)}
              cols={80}
              style={{ border: 'none', overflowY: 'scroll', width: '100%', height: '100%' }}
            />
          </Box>
        </SlidingDetailsPanel>
      </Well>
    </Box>
  );
};

export default AppleComamndsGrid;
