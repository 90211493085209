import { css } from '@tanium/style-system';
import styled from '@tanium/react-emotion-9';
import { Box } from '@tanium/react-box';

const PageContent = styled(Box)(
  css({
    marginTop: '3em',
    marginLeft: '2em',
    marginRight: '2em',
  })
);

const TitleBox = styled(Box)(
  css({
    fontSize: '1.2em',
    display: 'flex',
    flexWrap: 'wrap',
  })
);

const BackBox = styled(Box)(
  css({
    marginTop: '4.5em',
    marginLeft: '1em',
    color: 'black',
    textDecoration: 'underlined',
  })
);

const InfoBox = styled(Box)(
  css({
    borderStyle: 'solid',
    borderColor: '#DCDFE6',
    borderWidth: '0.1em',
    borderRadius: '0.4em',
  })
);

export { InfoBox, PageContent, TitleBox, BackBox };
