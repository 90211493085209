"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WellknownCommandIDs = void 0;
class WellknownCommandIDs {
}
exports.WellknownCommandIDs = WellknownCommandIDs;
WellknownCommandIDs.RESTART_DEVICE = '10000000-0000-4000-8000-000000000001';
WellknownCommandIDs.SHUTDOWN_DEVICE = '10000000-0000-4000-8000-000000000002';
WellknownCommandIDs.DEVICE_LOCK = '10000000-0000-4000-8000-000000000003';
WellknownCommandIDs.ERASE_DEVICE = '10000000-0000-4000-8000-000000000004';
WellknownCommandIDs.PROFILE_LIST = '10000000-0000-4000-8000-000000000005';
WellknownCommandIDs.PROVISIONING_PROFILE_LIST = '10000000-0000-4000-8000-000000000006';
WellknownCommandIDs.INSTALLED_APPLICATION_LIST = '10000000-0000-4000-8000-000000000007';
WellknownCommandIDs.DEVICE_INFORMATION = '10000000-0000-4000-8000-000000000008';
WellknownCommandIDs.DEVICE_CONFIGURED = '10000000-0000-4000-8000-000000000009';
WellknownCommandIDs.RESTRICTIONS = '10000000-0000-4000-8000-00000000000a';
WellknownCommandIDs.VALIDATE_APPLICATIONS = '10000000-0000-4000-8000-00000000000b';
WellknownCommandIDs.MANAGED_APPLICATION_LIST = '10000000-0000-4000-8000-00000000000c';
WellknownCommandIDs.MANAGED_MEDIA_LIST = '10000000-0000-4000-8000-00000000000d';
WellknownCommandIDs.ACCOUNT_CONFIGURATION = '10000000-0000-4000-8000-00000000000e';
WellknownCommandIDs.CLEAR_PASSCODE = '10000000-0000-4000-8000-00000000000f';
WellknownCommandIDs.CLEAR_RESTRICTIONS_PASSWORD = '10000000-0000-4000-8000-000000000010';
WellknownCommandIDs.SET_AUTO_ADMIN_PASSWORD = '10000000-0000-4000-8000-000000000011';
WellknownCommandIDs.SET_FIRMWARE_PASSWORD = '10000000-0000-4000-8000-000000000012';
WellknownCommandIDs.VERIFY_FIRMWARE_PASSWORD = '10000000-0000-4000-8000-000000000013';
WellknownCommandIDs.SCHEDULE_OS_UPDATE_SCAN = '10000000-0000-4000-8000-000000000014';
WellknownCommandIDs.AVAILABLE_OS_UPDATES = '10000000-0000-4000-8000-000000000015';
WellknownCommandIDs.OS_UPDATE_STATUS = '10000000-0000-4000-8000-000000000016';
WellknownCommandIDs.ENABLE_LOST_MODE = '10000000-0000-4000-8000-000000000017';
WellknownCommandIDs.DEVICE_LOCATION = '10000000-0000-4000-8000-000000000018';
WellknownCommandIDs.PLAY_LOST_MODE_SOUND = '10000000-0000-4000-8000-000000000019';
WellknownCommandIDs.DISABLE_LOST_MODE = '10000000-0000-4000-8000-00000000001a';
WellknownCommandIDs.SET_RECOVERY_LOCK = '10000000-0000-4000-8000-00000000001b';
WellknownCommandIDs.VERIFY_RECOVERY_LOCK = '10000000-0000-4000-8000-00000000001c';
WellknownCommandIDs.REQUEST_MIRRORING = '10000000-0000-4000-8000-00000000001d';
WellknownCommandIDs.STOP_MIRRORING = '10000000-0000-4000-8000-00000000001e';
WellknownCommandIDs.DISABLE_REMOTE_DESKTOP = '10000000-0000-4000-8000-00000000001f';
WellknownCommandIDs.ENABLE_REMOTE_DESKTOP = '10000000-0000-4000-8000-000000000020';
WellknownCommandIDs.SETTINGS = '10000000-0000-4000-8000-000000000021';
WellknownCommandIDs.SECURITY_INFO = '10000000-0000-4000-8000-000000000022';
WellknownCommandIDs.CERTIFICATE_LIST = '10000000-0000-4000-8000-000000000023';
WellknownCommandIDs.ACTIVATION_LOCK_BYPASS_CODE = '10000000-0000-4000-8000-000000000024';
WellknownCommandIDs.CLEAR_ACTIVATION_LOCK_BYPASS_CODE = '10000000-0000-4000-8000-000000000025';
WellknownCommandIDs.ROTATE_FILE_VAULT_KEY = '10000000-0000-4000-8000-000000000026';
WellknownCommandIDs.ACTIVE_NS_EXTENSIONS = '10000000-0000-4000-8000-000000000027';
WellknownCommandIDs.NS_EXTENSIONS_MAPPINGS = '10000000-0000-4000-8000-000000000028';
WellknownCommandIDs.USER_LIST = '10000000-0000-4000-8000-000000000029';
WellknownCommandIDs.LOG_OUT_USER = '10000000-0000-4000-8000-00000000002a';
WellknownCommandIDs.DECLARATIVE_MANAGEMENT = '10000000-0000-4000-8000-00000000002b';
WellknownCommandIDs.CONFIGURE_PROFILE = '10000000-0000-4000-8000-000100000001';
WellknownCommandIDs.INSTALL_PLATFORM_DEFAULT_CONFIG_PROFILE = '10000000-0000-4000-8000-00000000002c';
WellknownCommandIDs.INSTALL_DEVICE_SPECIFIC_CONFIG_PROFILE = '10000000-0000-4000-8000-00000000002d';
