/* eslint-disable no-console */
import React, { ReactElement, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { ColumnsChangeEvent, DataGrid, DataColumn } from '@tanium/react-data-grid';
import { Well } from '@tanium/react-well';
import { getEnrollmentStatusTextWithIcon, getPlatformTextWithIcon, toDateString } from './UIHelpers';
import { DeviceInfo } from '../utils/types';
import TenantContext from './Tenant/TenantContext';

interface Props {
  accountId: string;
  devices: DeviceInfo[];
  instanceApiUrl: string;
}

const DevicesGrid: React.VFC<Props> = ({ accountId, devices, instanceApiUrl }) => {
  const history = useHistory();
  const tenantContext = useContext(TenantContext);

  const initialColumns: DataColumn[] = [
    {
      label: 'Device Id',
      dataKey: 'deviceId',
      width: 280,
    },
    {
      label: 'Platform',
      dataKey: 'platform',
      cellRenderer: ({ rowData }: any): ReactElement => {
        const deviceInfo = rowData as DeviceInfo;
        return getPlatformTextWithIcon(deviceInfo.platform);
      },
    },
    {
      label: 'Enrollment Status',
      dataKey: 'enrollmentStatus',
      cellRenderer: ({ rowData }: any): ReactElement => {
        const deviceInfo = rowData as DeviceInfo;
        return getEnrollmentStatusTextWithIcon(deviceInfo.enrollmentStatus);
      },
    },
    {
      label: 'Last Checked In',
      dataKey: 'lastCheckInTime',
      cellRenderer: ({ rowData }: any): ReactElement => {
        const deviceInfo = rowData as DeviceInfo;
        return <span>{toDateString(deviceInfo.lastCheckInTime)}</span>;
      },
    },
    {
      label: 'Details',
      dataKey: 'details',
      headerAlignment: 'center',
      cellRenderer: ({ rowData }: any): ReactElement => {
        const deviceInfo = rowData as DeviceInfo;
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button size="sm" onClick={() => viewDeviceDetails(deviceInfo)}>
              View
            </Button>
          </Box>
        );
      },
    },
    {
      label: 'Commands',
      dataKey: 'commands',
      headerAlignment: 'center',
      cellRenderer: ({ rowData }: any): ReactElement => {
        const deviceInfo = rowData as DeviceInfo;
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button size="sm" onClick={() => viewDeviceCommands(deviceInfo)}>
              View
            </Button>
          </Box>
        );
      },
    },
  ];

  const viewDeviceDetails = (deviceInfo: DeviceInfo) => {
    tenantContext.setCurrentDevice(deviceInfo);
    history.push('device');
  };

  const viewDeviceCommands = (deviceInfo: DeviceInfo) => {
    tenantContext.setCurrentDevice(deviceInfo);
    history.push('commands');
  };

  const [columns, setColumns] = useState(initialColumns);
  const [data] = useState(devices);

  /* istanbul ignore next */
  const onColumnsChange = React.useCallback(
    (e: ColumnsChangeEvent, applyChanges: (currentColumns: DataColumn[], newColumns: DataColumn[]) => DataColumn[]) => {
      const changedColumns = applyChanges(columns, e.columns);
      setColumns(changedColumns);
    },
    [columns]
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const dataGetter = useCallback(({ index: i }) => data[i], [data]);

  return (
    <Box>
      <Well>
        <DataGrid
          height={400}
          columns={columns}
          onColumnsChange={onColumnsChange}
          isRowLoaded={() => true}
          rowCount={devices.length}
          // @ts-expect-error dataGetter wants Device to have an `id` but we override that in rowIdGetter
          dataGetter={dataGetter}
          rowIdGetter={(i) => dataGetter(i).deviceId}
        />
      </Well>
    </Box>
  );
};

export default DevicesGrid;
