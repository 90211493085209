import React from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { RED } from '@tanium/colors';
import ContainerBox, { AppHeaderStyle } from '../styles/containers/ContainerBox';
import logo from '../../static/tanium_logo.svg';

const SignOut = styled('a')(
  css({
    fontSize: 12,
    ':hover': {
      color: '#DCDFE6',
      cursor: 'pointer',
    },
  })
);

const UserNameBox = styled(Box)(
  css({
    fontSize: 14,
  })
);

const TaniumLogo: React.VFC = () => {
  return <img src={logo} alt="Tanium logo" height={24} />;
};
interface Props {
  username: string | undefined;
}

const Header: React.VFC<Props> = ({ username }) => {
  const history = useHistory();

  const onClickSignIn = () => {
    history.push('/login');
  };

  const onClickSignOut = () => {
    Auth.signOut().catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error);
      history.push('/');
    });
  };

  return (
    <Box position="fixed" top={0} width="100%" zIndex={99}>
      <Box bg={RED} height={`${AppHeaderStyle.borderHeight}px`} width="100%" />
      <ContainerBox>
        <Box mx={3}>
          <TaniumLogo />
        </Box>
        <Box flexGrow={1} />
        <>
          {username !== undefined && (
            <>
              <UserNameBox mx={2}>{username}</UserNameBox>
              <Box>|</Box>
              <Box mx={2}>
                <SignOut onClick={onClickSignOut}>Sign Out</SignOut>
              </Box>
            </>
          )}
          {username === undefined && (
            <>
              <Box mx={2}>
                <SignOut onClick={onClickSignIn}>Sign In</SignOut>
              </Box>
            </>
          )}
        </>
      </ContainerBox>
    </Box>
  );
};

export default Header;
