import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, useRouteMatch } from 'react-router-dom';
import { Box } from '@tanium/react-box';
import { PageContent } from './Styling';
import Header from '../components/Header/Header';
import TopContent from '../components/TopContent/TopContent';
import FindTenant from '../components/Tenant/FindTenant';
import TenantContext from '../components/Tenant/TenantContext';
import { getUsername } from '../utils/auth';
import protectedRoute from './ProtectedRoute';
import AppleCommands from '../components/Tenant/AppleCommands';
import Device from '../components/Tenant/Device';
import Devices from '../components/Tenant/Devices';
import { ApnsConfigInfo, DeviceInfo, TenantInfo } from '../utils/types';

const HomePage: React.VFC = () => {
  const match = useRouteMatch();
  const [user, setUser] = useState('');
  const [instanceName, setInstanceName] = useState<string | undefined>();
  const [instanceSupportApiUrl, setInstanceInstanceSupportApiUrl] = useState<string | undefined>();
  const [tenant, setTenant] = useState<TenantInfo | undefined>();
  const [apnsConfig, setApnsConfig] = useState<ApnsConfigInfo | undefined>();
  const [currentDevice, setCurrentDevice] = useState<DeviceInfo | undefined>();

  const tenantContext = {
    instanceName,
    setInstanceName,
    instanceSupportApiUrl,
    setInstanceInstanceSupportApiUrl,
    tenant,
    setTenant,
    apnsConfig,
    setApnsConfig,
    currentDevice,
    setCurrentDevice,
  };

  useEffect(() => {
    getUsername()
      .then((username) => {
        setUser(username);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  return (
    <Box>
      <Box>
        <Header username={user} />
        <PageContent>
          <TopContent />
          <TenantContext.Provider value={tenantContext}>
            <BrowserRouter>
              <Switch>
                <Route path={`${match.path}/devices`}>
                  <Devices />
                </Route>
                <Route path={`${match.path}/device`}>
                  <Device />
                </Route>
                <Route path={`${match.path}/commands`}>
                  <AppleCommands />
                </Route>
                <Route path={`${match.path}/tenant`}>
                  <FindTenant />
                </Route>
                <Route path={`${match.path}`}>
                  <FindTenant />
                </Route>
              </Switch>
            </BrowserRouter>
          </TenantContext.Provider>
        </PageContent>
      </Box>
    </Box>
  );
};

export default protectedRoute(HomePage);
