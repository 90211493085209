import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import Amplify from 'aws-amplify';
import axios from 'axios';

export type ConfigData = {
  mdmDomainName: string;
  instanceList: string[];
  internalCognitoAppClient: string;
  internalCognitoDomain: string;
  internalCognitoAdditionalCallbackUrls: string[];
  internalCognitoUserPoolId: string;
  internalCognitoUserPoolRegion: string;
};

type GetConfigResponse = {
  data: any;
};

const configureAmplify = (response: ConfigData) => {
  const amplifyConfig = {
    region: response.internalCognitoUserPoolRegion,
    userPoolId: response.internalCognitoUserPoolId,
    userPoolWebClientId: response.internalCognitoAppClient,
    mandatorySignIn: false,
    oauth: {
      domain: response.internalCognitoDomain,
      redirectSignIn: `${window.location.origin}/home`,
      redirectSignOut: `${window.location.origin}`,
      scope: ['openid', 'email', 'profile'],
      responseType: 'code',
    },
  };
  Amplify.configure({ Auth: amplifyConfig });
};

// used for local dev
const getDevConfig = () => {
  const localConfig: ConfigData = {
    mdmDomainName: process.env.REACT_APP_MDM_DOMAIN_NAME!,
    instanceList: [process.env.REACT_APP_LOCAL_INSTANCE_NAME!],
    internalCognitoAppClient: process.env.REACT_APP_INTERNAL_COGNITO_APP_CLIENT!,
    internalCognitoDomain: process.env.REACT_APP_INTERNAL_COGNITO_DOMAIN!,
    internalCognitoAdditionalCallbackUrls: [''],
    internalCognitoUserPoolId: process.env.REACT_APP_INTERNAL_COGNITO_USER_POOL_ID!,
    internalCognitoUserPoolRegion: process.env.REACT_APP_INTERNAL_COGNITO_USER_POOL_REGION!,
  };
  return localConfig;
};

export const configureApp = async () => {
  let config: ConfigData;
  if (process.env.NODE_ENV === 'development') {
    config = getDevConfig();
  } else {
    const response = await axios.get<GetConfigResponse>('/config');
    config = mapKeys(response.data, (value, key) => camelCase(key)) as ConfigData;
  }
  configureAmplify(config);
};
