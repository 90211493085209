import {
  CircleCheckIcon,
  StateCriticalIcon,
  StateIdleIcon,
  StateInProgressIcon,
  StatePendingIcon,
  MacIcon,
  MonitorIcon,
  WindowsIcon,
} from '@tanium/react-graphics';
import { TextWithIcon } from '@tanium/react-text-with-icon';
import { EnrollmentStatus, Platform } from '@tanium-cloud/mdm-device-entities';
import { WellknownCommandIDs } from '@tanium-cloud/mdm-apple-command-entities';
import xmlFormatter from 'xml-formatter';
import { TenantInfo } from '../utils/types';

export const prettyPrintXml = (xmlStr: string): string => {
  if (!xmlStr) {
    return '';
  }
  return xmlFormatter(xmlStr);
};

export const toDateString = (millis: number | undefined): string => {
  if (!millis) {
    return 'N/A';
  }
  return new Date(millis).toLocaleString();
};

export const getWellKnownCommandName = (id: string) => {
  let name = id; // just return id if not a well known command
  for (const [key, value] of Object.entries(WellknownCommandIDs)) {
    if (value === id) {
      name = key;
      break;
    }
  }
  return name;
};

export const enrollmentStatusToString = (enrollmentStatus: EnrollmentStatus | undefined): string => {
  switch (enrollmentStatus) {
    case EnrollmentStatus.Unenrolled:
      return 'Not Enrolled';
    case EnrollmentStatus.PendingCert:
      return 'Pending cert';
    case EnrollmentStatus.CertIssued:
      return 'Cert issues';
    case EnrollmentStatus.AppleAuthenticated:
      return 'Apple authenticated';
    case EnrollmentStatus.EnrollmentComplete:
      return 'Enrolled';
    case EnrollmentStatus.EnrollmentFailed:
      return 'Failed';
    default:
      return 'Unknown status';
  }
};

export const getPlatformTextWithIcon = (platform: string): JSX.Element => {
  let statusIcon;
  let statusText;
  switch (platform) {
    case Platform.Apple:
      statusIcon = MacIcon;
      statusText = 'Apple';
      break;
    case Platform.Windows:
      statusIcon = WindowsIcon;
      statusText = 'Windows';
      break;
    default:
      statusIcon = MonitorIcon;
      statusText = platform;
      break;
  }
  return <TextWithIcon leadingIcon={statusIcon}>{statusText}</TextWithIcon>;
};

export const getEnrollmentStatusTextWithIcon = (enrollmentStatus: EnrollmentStatus): JSX.Element => {
  let statusIcon;
  const statusText = enrollmentStatusToString(enrollmentStatus);
  switch (enrollmentStatus) {
    case EnrollmentStatus.Unenrolled:
      statusIcon = StateIdleIcon;
      break;
    case EnrollmentStatus.PendingCert:
      statusIcon = StatePendingIcon;
      break;
    case EnrollmentStatus.CertIssued:
      statusIcon = StateCriticalIcon;
      break;
    case EnrollmentStatus.AppleAuthenticated:
      statusIcon = StateInProgressIcon;
      break;
    case EnrollmentStatus.EnrollmentComplete:
      statusIcon = CircleCheckIcon;
      break;
    case EnrollmentStatus.EnrollmentFailed:
      statusIcon = StateCriticalIcon;
      break;
    default:
      statusIcon = StateInProgressIcon;
  }
  return <TextWithIcon leadingIcon={statusIcon}>{statusText}</TextWithIcon>;
};

export const getCognitoMetadataUrl = (tenant: TenantInfo): string => {
  return `https://cognito-idp.${tenant.cognitoUserPoolRegion}.amazonaws.com/${tenant.cognitoUserPoolId}/.well-known/openid-configuration`;
};

export default toDateString;
