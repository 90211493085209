/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { Panel } from '@tanium/react-panel';
import { getAppleCommandsByDevice } from '../../utils/supportApi';
import TenantContext from './TenantContext';
import AppleCommandsGrid from './AppleCommandsGrid';
import { AppleCommand } from '../../utils/types';

const AppleCommands: React.VFC = () => {
  const history = useHistory();
  const [appleCommands, setAppleCommands] = useState<AppleCommand[] | undefined>();
  const { tenant, currentDevice, instanceSupportApiUrl } = useContext(TenantContext);

  useEffect(() => {
    getAppleCommandsByDevice(currentDevice!.deviceId, tenant!.accountId, instanceSupportApiUrl!)
      .then((retrievedAppleCommands) => {
        setAppleCommands(retrievedAppleCommands);
        console.log('got apple commands');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [currentDevice, instanceSupportApiUrl, tenant]);

  const showDeviceList = () => {
    history.push('devices');
  };

  return (
    <>
      <Button onClick={showDeviceList}>Back to Device List</Button>
      <Box mt={2}>
        <Panel title={`Apple Commands for Device ${currentDevice!.deviceId}`}>
          {appleCommands && <AppleCommandsGrid appleCommands={appleCommands}></AppleCommandsGrid>}
        </Panel>
      </Box>
    </>
  );
};

export default AppleCommands;
