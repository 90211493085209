import React from 'react';
import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { Stack } from '@tanium/react-stack';
import { css } from '@tanium/style-system';
import { ThemeColors } from '@tanium/theme-data';
import { AppHeaderStyle } from '../styles/containers/ContainerBox';
import MdmLogo from './MdmLogo';
import TaniumTM from './TaniumTM';

const headerHeight = AppHeaderStyle.borderHeight + AppHeaderStyle.containerHeight;

const TopContentStyle = {
  containerHeight: 80,
  dividerHeight: 1,
};

const Container = styled(Box)(
  css({
    marginTop: `${headerHeight}px`,
    color: ThemeColors.Active2,
    height: `${TopContentStyle.containerHeight}px`,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
  })
);

const MdmModule = styled('span')(
  css({
    fontWeight: 'bold',
    fontSize: '28px',
    color: '#1E2432',
  })
);

const TopContent: React.VFC = () => {
  return (
    <Box width="100%" zIndex={2}>
      <Container>
        <Box mx={0} mr={1}>
          <MdmLogo />
        </Box>
        <Box mx={0}>
          <Stack>
            <TaniumTM />
          </Stack>
          <Stack>
            <MdmModule>MDM Support Portal</MdmModule>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default TopContent;
